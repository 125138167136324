.has-mobile-nav body.user_mode {
   padding-top: 10px;
}

.user_mode #siteContainer {
   //max-width: 100%;
   padding: 0;

   .col-md-6 {
      width: 50%;
      float: left;
   }
   // .splash-buttons {
   //    background-color: rgba(0,0,0,.4);
   //    margin-top: 244px;
   // }
}

.user_mode #siteContainer .yieldPageContent {
   padding-top: 0;
}
// .user_mode .splash-photos {
//    margin: 0 auto;
//    display: flex;
//    flex-flow: column wrap;
//    justify-content: center;
//    background-repeat: no-repeat;
//    background-position: center bottom;
//    margin-bottom: 50px;
//
//    .column {
//       max-width: 470px;
//       margin-bottom: 20px;
//    }
// }

.user_mode .splash-banner {
   padding-top: 73px;
}

.user_mode .splash-photo {
   .heroPhotoElement {
      //font-family: $splashFontFamily;
      padding: 0;
      margin: 0;
      position: relative;
      z-index: 100;

      img {
         transition: filter .5s;
         position: relative;
         z-index: 0;
      }

      a:hover {
         text-decoration: none;
         color: #057509;

         p {
            color: #057509;
         }

         img {
            filter: drop-shadow(0 0 15px white)
         }
      }
      // .cutline,
      // img {
      //    border: none;
      //    padding: 0 40px;
      // }
      // .cutline {
      //    font-weight: 700;
      //    font-size: 1.3em;
      //    line-height: 41px;
      //    font-style: normal;
      //    text-align: center;
      //    letter-spacing: 4px;
      //    height: 41px;
      //    color: #fff;
      //    background: none;
      //    background-position: center top;
      //    background-size: 100% 41px;
      //    background-repeat: no-repeat;
      //    position: relative;
      //    z-index: 200;
      //    top: -8px;
      //    padding-top: 20px;
      // }
   }
}

.user_mode .splash-buttons a img {
   transition: opacity .5s ease-in-out;

   &:hover {
      opacity: .8;
   }
}

.user_mode .splash-bar {
   // position: relative;
   // top: -57px;
   // z-index: 0;
   display: none;
}
// Tablet
@media only screen and (min-width: 768px) {
   // .site-background {
   //    background-size: contain;
   // }
   // .user_mode #siteContainer {
   //    .splash-buttons {
   //       background-color: rgba(0,0,0,.4);
   //       margin-top: 350px;
   //       padding: 0 15%;
   //    }
   // }
   .has-mobile-nav body.user_mode {
      padding-top: 44px;
   }

   .user_mode #siteContainer .yieldPageContent {
      padding-top: 28px;
   }

   .user_mode .splash-banner {
      padding-top: 304px;
   }

   .user_mode footer.snFooterContainer {
      margin-top: 75px;
   }
}
// Desktop
@media only screen and (min-width: 1024px) {
   // .site-background {
   //    background-size: 75% auto;
   // }
   .user_mode #siteContainer {
      max-width: 1200px;
      // .splash-buttons {
      //    margin-top: 436px;
      //    padding: 0 20%;
      // }
      .splash-buttons .col-md-6 {
         .heroPhotoElement {
            max-width: 478px;
         }

         .right .heroPhotoElement {
            float: right;
         }
      }
   }

   .user_mode #siteContainer .yieldPageContent {
      padding-top: 20px;
   }

   .user_mode .splash-banner {
      padding-top: 267px;
   }

   .user_mode footer.snFooterContainer {
      margin-top: 0;
   }
}
